// Angular Setup

var neiJquery = jQuery.noConflict(true);

var app = angular.module('neiJobsPortal', ['ngRoute', 'ngAnimate', 'ui.date', 'angularMoment', 'ngTagsInput', 'ngSanitize', 'oitozero.ngSweetAlert','720kb.socialshare']);

app.value('baseUrl', 'https://nei0615-p002.reusser.design/');
app.value('countyLimit', false);

app.config(function ($routeProvider) {

    var routes = {
        '/': {
            templateUrl: "jobs.html",
            controller: "JobsController",
            reloadOnSearch: false,
        },
        '/details/:id': {
            templateUrl: "job-detail.html",
            controller: "JobsController",
        },
        '/internship/:id': {
            templateUrl: "internship-detail.html",
            controller: "JobsController",
        },
        '/post-a-job': {
            templateUrl: "post-a-job.html",
            controller: "JobsController",
        },
        '/post-a-job/confirm': {
            templateUrl: "post-a-job-confirm.html",
            controller: "JobsController",
        },
        '/post-a-job/success': {
            templateUrl: "post-a-job-success.html",
            controller: "JobsController",
        },
        '/notifications': {
            templateUrl: "notification-sign-up.html",
            controller: "NotificationsController",
        },
        '/notifications/success': {
            templateUrl: "notification-success.html",
            controller: "NotificationsController",
        },
        '/notifications/unsubscribe': {
            templateUrl: "notification-unsubscribe.html",
            controller: "NotificationsController",
        },
        '/contact': {
            templateUrl: "contact-us-landing.html",
            controller: "ContactController",
        },
        '/contact/form': {
            templateUrl: "contact-us-form.html",
                        cache:true,
            controller: "ContactController",
        },
        '/contact/success': {
            templateUrl: "contact-us-success.html",
                        cache:true,
            controller: "ContactController",
        },
    }

    var app = neiJquery('div[ng-app="neiJobsPortal"]');
    if (app.length > 0) {
        Object.keys(routes).forEach(function(key){
            $routeProvider.when(key, routes[key]);
        });
    }

}).run(function ($rootScope, $window, $location, $anchorScroll, $timeout, $routeParams, $route, $rootElement, BaseService, SweetAlert, countyLimit) {
    $rootScope.countyLimit = countyLimit;
    $rootScope.counties = [];
    $rootScope.categories = [];
    $rootScope.targetIndustries = [];
    $rootScope.parseInt = parseInt;

    $rootScope.safeApply = function(callback) {
        $timeout(function(){
            if (callback) {
                $rootScope.$apply(callback);
            } else {
                $rootScope.$apply();
            }
        }, 0);
    }

    var app = neiJquery('div[ng-app="neiJobsPortal"]');

    $rootScope.config = {
        app: app.length > 0
    };

    $rootScope.user = {
        show: {
            filters: false
        }
    };

    $rootScope.defaultRouteController = "JobsController";

    $rootScope.routes = {
        '/': {
            templateUrl: "jobs.html",
            controller: "JobsController",
        },
        '/details/:id': {
            templateUrl: "job-detail.html",
            controller: "JobsController",
        },
        '/internship/:id': {
            templateUrl: "internship-detail.html",
            controller: "JobsController",
        },
        '/post-a-job': {
            templateUrl: "post-a-job.html",
            controller: "JobsController",
        },
        '/post-a-job/confirm': {
            templateUrl: "post-a-job-confirm.html",
            controller: "JobsController",
        },
        '/post-a-job/success': {
            templateUrl: "post-a-job-success.html",
            controller: "JobsController",
        },
        '/notifications': {
            templateUrl: "notification-sign-up.html",
            controller: "NotificationsController",
        },
        '/notifications/success': {
            templateUrl: "notification-success.html",
            controller: "NotificationsController",
        },
        '/notifications/unsubscribe': {
            templateUrl: "notification-unsubscribe.html",
            controller: "NotificationsController",
        },
        '/contact': {
            templateUrl: "contact-us-landing.html",
            controller: "ContactController",
        },
        '/contact/form': {
            templateUrl: "contact-us-form.html",
            controller: "ContactController",
        },
        '/contact/success': {
            templateUrl: "contact-us-success.html",
            controller: "ContactController",
        },
    }

    $rootScope.route = {
        route: '',
        templateUrl: 'jobs.html',
        controller: 'JobsController',
        params: {},
        change: function (route) {
            if ($rootScope.config.app) {
                $location.path(route);
            } else {
                var parsed = $rootScope.route.parse(route);
                route = parsed;
                if ($rootScope.routes[route]) {
                    $rootScope.route.route = route;
                    $rootScope.route.templateUrl = $rootScope.routes[route].templateUrl;
                    $rootScope.route.controller = $rootScope.routes[route].controller || $rootScope.defaultRouteController;

                    // firing an event downwards
                    $rootScope.$broadcast('routeChange', {});
                }
            }
        },
        parse: function (route) {
            var arr = route.split('/');
            var ids = [];
            var params = {};
            arr.forEach(function(piece,i) {
                var int = parseInt(piece);
                if (typeof int == 'number' && !isNaN(int)) {
                    ids.push(i);
                }
            });
            ids.forEach(function(i,i2){
                params.id = arr[i];
                arr[i] = ':id';
            });
            var newRoute = arr.join('/');
            $rootScope.route.params = params;
            return newRoute;
        },
        getParam:function(param) {
            if ($rootScope.config.app) {
                var nextPath = $location.path();
                var nextRoute = $route.routes[nextPath]
                return $route.current.params[param];
            } else {
                return $rootScope.route.params[param];
            }
        }
    }

    $rootScope.updateBreadCrumbsIfNeeded = function() {

        $rootScope.safeApply(function() {

            // Update side nav and breadcrumb all in one swoop
            var foundLink = false;
            var pageTitle = neiJquery('.nei_job_widget_page_title')
            var title = pageTitle;
            var sideNavLinks = neiJquery('.side-nav').find('.level-1')
            var breadcrumb = neiJquery('.breadcrumbs').find('li.last');
            if (sideNavLinks && sideNavLinks.length) {
                var path = $window.location.href;
                neiJquery.each(sideNavLinks, function(i, elem) {
                    var anchors = neiJquery(elem).children('a');
                    if (anchors.length && anchors[0].href == path) {
                        title = neiJquery(anchors[0]);
                        $(elem).addClass("active")
                        breadcrumb.text(title.text());
                        foundLink = true;
                    }
                })
                if (foundLink) {
                    neiJquery.each(sideNavLinks, function(i, elem) {
                        var anchors = neiJquery(elem).children('a');
                        if (anchors.length && anchors[0].href != path) {
                            $(elem).removeClass("active");
                        }
                    });
                }
            }
        })
    }

    var routeChangeSuccess = function(event, next, current) {
        var route = $location.path();
        if (route == "") route = "/";
        $rootScope.route.route = route;
        if ($rootScope.config.app) {
            if ($route.current) {
                $rootScope.route.templateUrl = $route.current.loadedTemplateUrl;
                $rootScope.route.controller = $route.current.controller || $rootScope.routes[route].controller || $rootScope.defaultRouteController;
            }

            $timeout(function() {
                $rootScope.$apply(function(){
                    $rootScope.$broadcast('routeChange', {});
                });
            });
            $rootScope.updateBreadCrumbsIfNeeded();
        }
    };

    if ($rootScope.config.app) {
        $window.gtag('config', 'AW-1016930841', { 'send_page_view': false });
        $rootScope.$on('$routeChangeSuccess', routeChangeSuccess);

        $rootScope.$on('$routeChangeSuccess', function() {
            // $window.gtag('config', 'AW-1016930841', {'page_path': $location.path()});
            // $window.gtag('event', 'page_view');
        });

    } else {
        $rootScope.$on('$locationChangeSuccess', routeChangeSuccess);
    }

    $rootScope.trackConversion = function(url) {
        if ($rootScope.config.app) {
            $window.gtag('event', 'conversion', {
                'send_to': 'AW-1016930841/l0YkCKHt1X4QmcT05AM',
                // 'event_callback': callback
                });
        }
        return false;
    }

    $rootScope.$on('$routeUpdate', function(){
        $rootScope.updateBreadCrumbsIfNeeded();
    });

    // parse query string
    $rootScope.parseQueryString = function () {
        var query = {};
        var arr = $window.location.href.split('?');
        var qstr = arr.length > 1 ? arr[1] : '';
        if (qstr.length > 0) {
            var a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
            for (var i = 0; i < a.length; i++) {
                var b = a[i].split('=');
                query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
            }
        }
        return query;
    }

    // global pattern
    $rootScope.phoneNumberPattern = (function() {
        var regexp = /^[1]?[ .-]?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
        return {
            test: function(value) {
                if( $rootScope.requireTel === false ) {
                    return true;
                }
                return regexp.test(value);
            }
        };
    })();

    var query = $rootScope.parseQueryString();
    if (query['nei-action']) {
        var action = query['nei-action'];
        if (action.substr(0, 1) != '/') action = '/' + action;
        $rootScope.route.change(action);
    }

    $rootScope.deleteKey = function(obj,key) {
        delete obj[key];
    }

    $rootScope.openExternalLink = function(url){
        SweetAlert.swal({
            title: "The journey continues!",
            text: "In pursuit of finding your next job, you'll be taken to one of our partner sites to get more information. Good luck in your search and don't forget to come back to the Northeast Indiana Regional Partnership to learn more about what our area has to offer!\n\nContinue to partner website?",
            showCancelButton: true,
            confirmButtonColor: "#0B4593",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            closeOnConfirm: true},
        function(confirm){
            if (confirm) {
                $rootScope.trackConversion(url);
                $window.open(url,'_blank');
            }
        });
    }

    if ($rootScope.config.app) {
        $rootScope.route.route = $location.path();
    } else {
        $rootScope.route.route = '/';
    }

    $rootScope.scrollToTop = function() {

        var element = document.querySelector('.ng-scope');
        var $rootElement = angular.element(element).injector().get('$rootElement');
        var targetPosition = angular.element($rootElement)[0].getBoundingClientRect().top - angular.element('body')[0].getBoundingClientRect().top;

        $rootScope.safeApply(function() {
            neiJquery('html, body').animate({ scrollTop: targetPosition }, 'fast');
        })
    }

    $rootScope.getBaseData = function () {
        if (!$rootScope.counties || $rootScope.counties.length < 1) {
            if ($rootScope.countyLimit) {
                $rootScope.counties = [{'county':$rootScope.countyLimit}];
            } else {
                BaseService.getCounties().then(function (res) {
                    $rootScope.counties = res.data.data;
                }).catch(function (res) {

                });
            }
        }

        if (!$rootScope.categories || $rootScope.categories.length < 1) {
            BaseService.getCategories().then(function (res) {
                $rootScope.categories = res.data.data;
            }).catch(function (res) {

            });
        }

        if (!$rootScope.targetIndustries || $rootScope.targetIndustries.length < 1) {
            BaseService.getTargetIndustries().then(function (res) {
                $rootScope.targetIndustries = res.data.data;
            }).catch(function (res) {

            });
        }
    }
    $rootScope.getBaseData();

    $rootScope.addDays = function(date,days) {
        var dat = new Date(date.valueOf());
        dat.setDate(dat.getDate() + days);
        return dat;
    }

    $rootScope.isApp = function() {
	    return $rootScope.config.app? true : false;
    }

    $rootScope.doBlur = function($event){
        var target = $event.target;
        target.blur();
        return true;
    }

    $timeout(function(){
        angular.element($rootElement).css("display", "");
    }, 300)
});

angular.element(document).ready(function ($timeout) {

    var baseUri = "s3.neindiana.com/jobs/";
    var script = neiJquery('script[src*="'+baseUri+'"]');
    var isApp = false;

    // Dev Mode only
    if (script.length < 1) {
        baseUri = '/_build/app.min.js';
        script = neiJquery('script[src*="'+baseUri+'"]');
    }

    // BASE API
    if (script.data('baseUrl')) {
        app.value('baseUrl',script.data('base-url'));
    }

    // Did we set a county limit?
    if (script.data('nei-county')) {
        app.value('countyLimit',script.data('nei-county'));
    }

    var widget = '<div id="neiJobsPortal" style="display:none"></div>';
    if (window.location.hostname.indexOf('neindiana.com') > -1 || window.location.hostname.indexOf('nei0615-p00') > -1) {
        if (script.data('mode') != 'widget') {
            widget = '<div id="neiJobsPortal" ng-app="neiJobsPortal" style="display:none"></div>';
            isApp = true;
        }
    }

    var src = script.attr('src');
    var split = src.split('/');
    split.splice(split.length - 1, 1);
    var join = split.join('/');
    neiJquery('<link rel="stylesheet" href="'+join+'/app.min.css">'+widget).insertAfter(script);

    var elem = document.getElementById("neiJobsPortal");
    if (elem) {

        // Widget Template
        var html = '<div dynamic-controller="route.controller" >';
            html += '<div id="nei_job_widget" class="ng-cloak">';
            html += '<div ng-include src="\'fragments/header.html\'"></div>';
            html += '<div ng-include src="route.templateUrl"></div>';
            html += '<div ng-include src="\'fragments/footer.html\'"></div>';
            html += '</div>';
        html += '</div>';

        // App Template
        if (isApp) {
            html = '<div id="nei_job_widget" class="ng-cloak">';
                html += '<div ng-include src="\'fragments/header.html\'"></div>';
                html += '<div ng-view></div>';
                html += '<div ng-include src="\'fragments/footer.html\'"></div>';
            html += '</div>';

            // Google Tag Manager
            var gtm = `<script async src="https://www.googletagmanager.com/gtag/js?id=AW-1016930841"></script>
            <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-1016930841', { 'send_page_view': false });
            </script>`;
            neiJquery(gtm).insertAfter(script);
        }

        angular.element(elem).html(html);
        angular.bootstrap(elem, ["neiJobsPortal"]);
    }
});

