app.controller('ContactController', ["$scope", "$rootScope", "$window", "$document", "$location", "ContactService", 'JobsService', function ($scope, $rootScope, $window, $document, $location, ContactService, JobsService) {

    if (!$rootScope.contact)
        $rootScope.contact = {
            counties: [],
            industries: [],
        };

    $scope.forms = {form:{}};

    $scope.error = '';

    $scope.submit = function () {
        $scope.error = '';
        ContactService.post($scope.contact).then(function (res) {
            if (res.data.success) {
                $scope.route.change('/contact/success');
            } else {
                $scope.error = res.data.message;
            }
        }).catch(function (res) {
            $scope.error = 'An error occurred sending your message. Please try again layer.'
        });
    }

    $scope.toggleChecked = function(option, arr, prop) {
        var index = arr.indexOf(option[prop]);
        if (index > -1) {
            arr.splice(index,1);
        } else {
            arr.push(option[prop]);
        }
    }
}]);

app.factory('ContactService', function ($http, baseUrl) {
    return {
        post: function (params) {
            return $http.post(baseUrl + 'api/v1/contact', params)
        }
    };
});
